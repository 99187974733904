<template>
  <div class="cont_popUpClientes  w-[90vw] md:w-[70vw] xl:w-[40vw] " >
    <div class="cont_btnCerrarPopUpClientes float-right flex justify-center items-center align-middle cursor-pointer border-2 border-red-600 text-red-600 bg-white rounded-full w-8 h-8 ml-1" @click="cerrarPopUp()">
      <i class="fa-solid fa-xmark scale-150"></i>
    </div>
    <div class="cont_imgPopUpClientes rounded-3xl overflow-hidden shadow-[0rem_1rem_2rem_-0.5rem_#00000080] cursor-pointer" @click="seleccionarProducto()">
      <img :src="'https://juanabapl.juanabonita.com.co:446/PED_WEB/Imagenes/Oferta_' + Campana + '.jpg'" alt="">
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { computed, ref } from "@vue/runtime-core";
import { watch } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'PopUpClientes',
    setup(){
        const store = useStore()
        
        const cerrarPopUp = () => {
          store.state.mostrarPopUpClientes = false
        }
        const dataUsuario = computed(() => store.state.dataUsuario)
        const Campana = ref("")

        const setCampana = () => {
          Campana.value = dataUsuario.value.infoCliente.CampañaIngresoMicrocolsa
          //console.log(dataUsuario.value.infoCliente.CampañaIngresoMicrocolsa)
        }


        if(dataUsuario.value.infoCliente != undefined){
          setCampana()
        }

        watch(dataUsuario, (N,V) => {
          //console.log(N)
          if(N.length != undefined || N.length > 0){
            setCampana()
          }
        })

        const seleccionarProducto = () => {
          cerrarPopUp()
          store.state.generoSeleccionado = {Genero: undefined, index: 0}
          store.state.categoriaSeleccionada = {Categoria: undefined, index: 0}
          store.state.grupoSeleccionado = {Grupo: undefined, index: 0}
          store.state.precioSeleccionado = {Precio: undefined, index: 0}
          store.state.referenciaSeleccionada = {Referencia: undefined, index: 0}
          store.state.paginaSeleccionada = {Pagina: 1, index: 0}
          router.push("/Seleccion")
        }

        return{
          cerrarPopUp,
          Campana,
          seleccionarProducto
        }
    }
}
</script>

<style>

</style>